import { PostalCodeLocale } from '@weave/design-system';
import { GetState, SetState } from 'zustand';

import { PostalCodeField } from '@forms-exp/types';
import { State } from '../useStore';

export interface ConfigSlice {
  postalCodeLocale: PostalCodeLocale;
  updatePostalCodeLocale: (newLocale: PostalCodeLocale) => void;
}

const createConfigSlice = (set: SetState<State>, get: GetState<State>) => ({
  postalCodeLocale: PostalCodeLocale.US,
  updatePostalCodeLocale: (newLocale: PostalCodeLocale) =>
    set((state) => {
      const newPatronInfoFields = { ...state.patronInfoFields };

      // Modify the locale of the postal code field in the patron info fields
      newPatronInfoFields.postalCode = {
        ...newPatronInfoFields.postalCode,
        locale: newLocale,
        value: '', // Reset the value
      };

      // Reset the value of the state dropdown field in the patron info fields
      newPatronInfoFields.state = {
        ...newPatronInfoFields.state,
        value: '', // Reset the value
      };

      const newPrimaryFields = { ...state.primaryFields };

      /**
       * Modify the locale of the postal code field in the primary fields:
       * 1. postalCode
       * 2. primaryInsurancePostalCode
       * 3. secondaryInsurancePostalCode
       */
      if (newPrimaryFields.postalCode) {
        newPrimaryFields.postalCode = {
          ...newPrimaryFields.postalCode,
          value: '', // Reset the value
          locale: newLocale,
        } as PostalCodeField;
      }

      if (newPrimaryFields.primaryInsurancePostalCode) {
        newPrimaryFields.primaryInsurancePostalCode = {
          ...newPrimaryFields.primaryInsurancePostalCode,
          value: '', // Reset the value
          locale: newLocale,
        } as PostalCodeField;
      }

      if (newPrimaryFields.secondaryInsurancePostalCode) {
        newPrimaryFields.secondaryInsurancePostalCode = {
          ...newPrimaryFields.secondaryInsurancePostalCode,
          value: '', // Reset the value
          locale: newLocale,
        } as PostalCodeField;
      }

      /**
       * Resrt the value of the state dropdown field in the primary fields
       * 1. State
       * 2. primaryInsuranceState
       * 3. secondaryInsuranceState
       */

      if (newPrimaryFields.state && newPrimaryFields.state.type === 'dropdown') {
        newPrimaryFields.state = {
          ...newPrimaryFields.state,
          value: '', // Reset the value
        };
      }

      if (
        newPrimaryFields.primaryInsuranceState &&
        newPrimaryFields.primaryInsuranceState.type === 'dropdown'
      ) {
        newPrimaryFields.primaryInsuranceState = {
          ...newPrimaryFields.primaryInsuranceState,
          value: '', // Reset the value
        };
      }

      if (
        newPrimaryFields.secondaryInsuranceState &&
        newPrimaryFields.secondaryInsuranceState.type === 'dropdown'
      ) {
        newPrimaryFields.secondaryInsuranceState = {
          ...newPrimaryFields.secondaryInsuranceState,
          value: '', // Reset the value
        };
      }

      const newFormFields = { ...state.fields };

      // Modify the locale of all the postal code fields in the form/packet
      for (const fieldId in newFormFields) {
        const field = newFormFields[fieldId];

        if (field.type === 'postalCode') {
          newFormFields[fieldId] = {
            ...field,
            locale: newLocale,
            value: '', // Reset the value
          };
        }
      }

      return {
        ...state,
        postalCodeLocale: newLocale,
        patronInfoFields: newPatronInfoFields,
        fields: newFormFields,
        primaryFields: newPrimaryFields,
      };
    }),
});

export default createConfigSlice;
