import { useState } from 'react';
import { useRouter } from 'next/router';

import axios, { AxiosError } from 'axios';

import { useAlert } from '@weave/design-system';

import { environmentVariables } from '@forms-exp/env';
import { useCountdownTimer } from '@forms-exp/hooks';
import { fetchFormData } from '@forms-exp/helpers';
import {
  FormTemplateFetchError,
  ParsedFormTemplate,
  Person,
  Insurance,
  MedicalHistory,
  PrePopulateElements,
} from '@forms-exp/types';

interface UserContact {
  phoneNumber: string | undefined;
  email: string | undefined;
}

interface RequestOtpResponse {
  success: boolean;
  isServerError?: boolean;
  noContactDetails?: boolean;
}

interface VerifyOtpResponse {
  isVerified: boolean;
  person?: Person;
  medicalHistory?: MedicalHistory;
  primaryInsurance?: Insurance;
  secondaryInsurance?: Insurance;
  prePopulateElements?: PrePopulateElements;
  isInvalidOtp?: boolean;
  isServerError?: boolean;
}

export const useOTP = () => {
  const [initiateOtp, setInitiateOtp] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [userDetails, setUserDetails] = useState<UserContact>({
    email: undefined,
    phoneNumber: undefined,
  });

  const alert = useAlert();
  const router = useRouter();

  const { qwe, company_id } = router.query as {
    qwe: string;
    company_id: string;
  };

  const { isCountdownComplete, secondsCountdown, restartCountdown } = useCountdownTimer({
    timeToElapse: 60,
    initiate: initiateOtp,
  });

  const requestOtp = async (resend = false): Promise<RequestOtpResponse> => {
    setIsOtpSent(false);

    const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/prepopulation/otp`;

    try {
      const res = await axios.post<UserContact>(url, {
        id: qwe,
      });
      setUserDetails((prev) => {
        return { ...prev, ...res.data };
      });

      setIsOtpSent(true);

      if (resend) {
        alert.success('Verification code sent successfully');
        restartCountdown();
      } else {
        setInitiateOtp(true);
      }

      return {
        success: true,
      };
    } catch (error) {
      const e = error as AxiosError;
      const isServerError = (e.response?.status ?? 400) >= 500;

      // we don't want to show error when we initiate a OTP request. In that case we directly redirect them to manual form
      if (resend || isServerError) {
        alert.error(
          'Failed to send verification code. Please fill out form manually if problem persists.'
        );
      }

      return {
        success: false,
        noContactDetails: !isServerError,
        isServerError,
      };
    }
  };

  const verifyOtp = async (secretCode: string): Promise<VerifyOtpResponse> => {
    setIsVerifyingOtp(true);
    try {
      const res = await fetchFormData({
        token: qwe,
        companyId: company_id,
        secretCode,
      });

      setIsVerifyingOtp(false);

      if ((res as FormTemplateFetchError).serverError) {
        return {
          isVerified: false,
          isServerError: true,
        };
      }

      if (!(res as FormTemplateFetchError).otpValidated) {
        return {
          isVerified: false,
          isInvalidOtp: true,
        };
      }

      return {
        isVerified: true,
        person: (res as ParsedFormTemplate).personDetails,
        medicalHistory: (res as ParsedFormTemplate).medicalHistory,
        primaryInsurance: (res as ParsedFormTemplate).primaryInsurance,
        secondaryInsurance: (res as ParsedFormTemplate).secondaryInsurance,
        prePopulateElements: (res as ParsedFormTemplate).prePopulateElements,
      };
    } catch (error) {
      setIsVerifyingOtp(false);
      return {
        isVerified: false,
        isServerError: true,
      };
    }
  };

  return {
    userDetails,
    isOtpSent,
    isVerifyingOtp,
    allowResend: isCountdownComplete,
    resendWaitTime: secondsCountdown,
    requestOtp,
    verifyOtp,
  };
};
