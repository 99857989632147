export interface EnvironmentVariables {
  baseApiUrl: string | undefined;
  adobeJSApiKey: string | undefined;
  environment: string | undefined;
  sentryDSN: string | undefined;
}

export const FORMS_ENV_PROP_KEY = 'formsEnv';

/**
 * This is to be used in the client side only.
 */
export interface EnvironmentVariableProps {
  [FORMS_ENV_PROP_KEY]: EnvironmentVariables;
}
