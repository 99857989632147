import useStore from '@forms-exp/store';
import { FormAction, FieldState, FormTerm, FormTemplateData } from '@forms-exp/types';

export const useBranchingLogic = (form: FormTemplateData) => {
  const {
    conditionMapper,
    fields: fieldState,
    performedActions,
    setActionValues,
  } = useStore();

  function performAction(actions: FormAction[], conditionId: string): void {
    const updatedFieldState: Record<string, FieldState> = {};
    actions.forEach((action) => {
      if (action.type === 'fields') {
        if (['show', 'hide'].includes(action.operation)) {
          updatedFieldState[action.target] = {
            ...fieldState[action.target],
          };
          updatedFieldState[action.target].hidden =
            action.operation === 'show' ? false : true;
        }

        if (action.operation === 'update' && action.value) {
          updatedFieldState[action.target] = {
            ...fieldState[action.target],
          };
          try {
            // can be boolean, string or string[]
            updatedFieldState[action.target].value = JSON.parse(action.value);
          } catch (error) {
            // if not parsable then just set the value coz it might be a string
            updatedFieldState[action.target].value = action.value;
          }
        }
      }
    });
    setActionValues(updatedFieldState, conditionId, true);
  }
  function undoAction(actions: FormAction[], conditionId: string): void {
    const updatedFieldState: Record<string, FieldState> = {};
    actions.forEach((action) => {
      if (action.type === 'fields') {
        if (['show', 'hide'].includes(action.operation)) {
          updatedFieldState[action.target] = {
            ...fieldState[action.target],
          };

          updatedFieldState[action.target].hidden =
            action.operation === 'show' ? true : false;
        }
      }
    });
    setActionValues(updatedFieldState, conditionId, false);
  }
  function isConditionSatisfied(
    term: FormTerm,
    fieldId: string,
    fieldValue: boolean | string | string[]
  ) {
    switch (term.check) {
      case 'contains':
        return (fieldValue as string).includes(term.value as string);
      case 'equals':
        if (term.field === fieldId) {
          if (typeof fieldValue === 'boolean') {
            return term.value === fieldValue.toString();
          }
          // checkboxes or multi-select
          if (Array.isArray(fieldValue)) {
            return fieldValue.includes(term.value as string);
          }
          return term.value === fieldValue;
        }
        if (typeof fieldValue === 'boolean') {
          return term.value === fieldState[term.field]?.value?.toString();
        }
        return term.value === fieldState[term.field]?.value;
      case 'answered':
        if (Array.isArray(fieldValue)) {
          return fieldValue.length > 0;
        }
        return fieldValue !== '';
      case 'click':
        return true;
      default:
        return false;
    }
  }
  const doConditionCheck = (fieldId: string) => {
    const field = fieldState[fieldId];
    const allConditions = form.conditions || [];
    const fieldConditions = form.fields[fieldId]?.condition_ids || [];
    if (allConditions.length === 0 || fieldConditions.length === 0) {
      return;
    }
    fieldConditions.forEach((id) => {
      const condition = allConditions[conditionMapper[form.form.id][id]];
      let conditionsSatisfied: boolean = true;
      if (condition.operator === 'and') {
        conditionsSatisfied = condition.terms.every((term) =>
          isConditionSatisfied(term, fieldId, field.value as string | string[] | boolean)
        );
      } else {
        conditionsSatisfied = condition.terms.some((term) =>
          isConditionSatisfied(term, fieldId, field.value as string | string[] | boolean)
        );
      }
      if (conditionsSatisfied && !performedActions[condition.id]) {
        performAction(condition.actions, condition.id);
      } else if (!conditionsSatisfied && performedActions[condition.id]) {
        // if actions already perfomed then undo them
        undoAction(condition.actions, condition.id);
      }
    });
  };

  return { doConditionCheck };
};
