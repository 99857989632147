import {
  FC,
  FormEvent,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useRouter } from 'next/router';

import {
  Chip,
  FamilyIcon,
  Heading,
  PrimaryButton,
  SecondaryButton,
  useAlert,
} from '@weave/design-system';

import useStore from '@forms-exp/store';
import { submitForm } from '@forms-exp/helpers';
import { useHasScroll, useIdleKioskRedirection } from '@forms-exp/hooks';
import { FooterLinks, CompanyLogo } from '@forms-exp/components';
import { SubmissionMode } from '@forms-exp/types';
import { analyticsTags } from '@forms-exp/constants';

import FormProgressBar from '../progress-bar/progress-bar.component';
import FormReview from '../form-review/form-review.component';
import FormElements from '../form-elements/form-elements.component';
import CountryField from '../form-elements/country-field/country-field.component';

import { FormExperienceProps, SubmissionStatusState } from '../form-experience.type';

import { useModifiedForms } from './useModifiedForms';
import {
  formStyle,
  scrollContainerStyle,
  formContentContainerStyle,
  getFormFooterStyle,
  primaryButtonStyle,
  headerDependentContainerStyle,
} from './custom-form.style';

interface CustomFormProps extends FormExperienceProps {
  allSectionsRef: MutableRefObject<string[]>;
  submissionInProgress: boolean;
  setSubmissionInProgress: Dispatch<SetStateAction<boolean>>;
  setSubmissionStatus: Dispatch<SetStateAction<SubmissionStatusState>>;
  isSolicitedForm: boolean;
  hideFooterLinks?: boolean;
  disableReviewMode?: boolean;
}

const CustomForm: FC<CustomFormProps> = ({
  isCountryDropdownEnabled,
  previewMode,
  forms,
  person,
  companyToken,
  formToken,
  formType,
  logoSrc,
  officeName,
  allSectionsRef,
  conditionMapper,
  submissionInProgress,
  setSubmissionStatus,
  setSubmissionInProgress,
  hideFooterLinks = false,
  disableReviewMode = false,
  isSolicitedForm,
}) => {
  const alert = useAlert();
  const formContentRef = useRef<HTMLDivElement>(null);
  const { firstName, lastName } = person || {};

  const {
    fields: fieldState,
    primaryFields: primaryFieldState,
    patronInfoReady,
    patronInfoFields,
    reviewMode,
    currentSection,
    currentFormIndex,
    initialized,
    patronSearchDetails,
    setHasUnsavedChanges,
    toggleFormReview,
    updateCurrentSection,
    goToPreviousForm,
    validateSection,
    goToNextForm,
  } = useStore();

  const router = useRouter();
  const { companyId, kiosk, for_dependent } = router.query as {
    companyId: string;
    kiosk?: string;
    for_dependent?: string;
  };

  //check if link is generated for a dependent
  const isFormForDependent = for_dependent === 'true';

  const isInKioskMode = kiosk !== undefined;
  const { IdleKioskRedirectionModal, idleTimerProps, redirectToKiosk } =
    useIdleKioskRedirection({
      isInKioskMode,
      companyId: companyId,
    });

  const inputFocusAndChangeHandler = useCallback(() => {
    idleTimerProps.resetCountdown();
  }, [idleTimerProps]);

  const { modifiedForms } = useModifiedForms({ forms });

  const { hasScroll, ScrollCue, scrollCueProps } = useHasScroll({
    externalElRef: formContentRef,
    key: currentFormIndex,
  });

  let currentForm = modifiedForms[currentFormIndex];
  let fieldsInThisSection: string[] = [];
  const sectionTitle = currentForm.sections[currentSection].title;

  const showPrevButton = allSectionsRef.current.indexOf(currentSection) > 0;
  const showReviewButton =
    allSectionsRef.current.indexOf(currentSection) === allSectionsRef.current.length - 1;

  if (initialized && currentSection) {
    fieldsInThisSection = currentForm.sections[currentSection].fields;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement> | undefined) {
    gtag('event', analyticsTags.submitForm);
    if (submissionInProgress) {
      return;
    }

    if (event) {
      event.preventDefault();

      if (previewMode) {
        setSubmissionStatus((state) => ({ ...state, submitted: true }));
        return;
      }

      let mode: SubmissionMode | undefined = undefined;

      if (isInKioskMode) {
        mode = SubmissionMode.UNSOLICITED_PATIENTKIOSK;
      } else if (!isSolicitedForm) {
        mode = SubmissionMode.UNSOLICITED_PUBLICLINKS;
      }

      idleTimerProps.resetCountdown();
      setSubmissionInProgress(true);
      const response = await submitForm({
        forms: modifiedForms,
        companyToken,
        fieldState,
        formToken,
        patronInfoFields,
        formType,
        primaryFieldState,
        mode,
        patronSearchDetails,
      });

      if (response.success) {
        const { submissionId, submittedFormData } = response;
        setHasUnsavedChanges(false);
        setSubmissionStatus((state) => ({
          ...state,
          submitted: true,
          id: submissionId || '',
          data: submittedFormData || '',
        }));
      } else {
        alert.error({ message: response.message || 'Failed to submit the form!' });
      }

      setSubmissionInProgress(false);
    }
  }

  function resetScrollPosition() {
    formContentRef.current?.scrollTo(0, 0);
  }

  function getNextSectionState(currentSectionIndex: number): number {
    const sectionsInForm = currentForm.form.sections;
    while (currentSectionIndex < sectionsInForm.length) {
      const currentSection =
        modifiedForms[currentFormIndex].sections[sectionsInForm[currentSectionIndex]];
      if (!currentSection.skip) {
        return currentSectionIndex;
      }
      currentSectionIndex++;
    }
    return currentSectionIndex;
  }

  /**
   * Function to handle the click on the back button.
   */
  function handleBack() {
    if (submissionInProgress) {
      return;
    }

    const sectionsInForm = currentForm.form.sections;
    const indexOfSectionInForm = sectionsInForm.indexOf(currentSection);
    resetScrollPosition();
    idleTimerProps.resetCountdown();

    if (reviewMode) {
      toggleFormReview();
      return;
    }

    if (indexOfSectionInForm > 0) {
      updateCurrentSection(sectionsInForm[indexOfSectionInForm - 1]);
      return;
    }

    if (indexOfSectionInForm === 0 && currentFormIndex > 0) {
      const prevForm = modifiedForms[currentFormIndex - 1];
      goToPreviousForm();
      updateCurrentSection(prevForm.form.sections[prevForm.form.sections.length - 1]);
      return;
    }
  }

  /**
   * Function to handle the click on the next button.
   */
  function handleNext() {
    const isValid = validateSection(fieldsInThisSection);
    idleTimerProps.resetCountdown();

    if (isValid) {
      resetScrollPosition();
      const sectionsInForm = currentForm.form.sections;
      const indexOfSectionInForm = sectionsInForm.indexOf(currentSection);
      const nextSectionIndex = getNextSectionState(indexOfSectionInForm + 1);

      if (nextSectionIndex <= sectionsInForm.length - 1) {
        updateCurrentSection(sectionsInForm[nextSectionIndex]);
        return;
      }

      if (
        nextSectionIndex === sectionsInForm.length &&
        currentFormIndex < modifiedForms.length - 1
      ) {
        const nextForm = modifiedForms[currentFormIndex + 1];
        goToNextForm();
        updateCurrentSection(nextForm.form.sections[0]);
        return;
      }

      if (!reviewMode && !disableReviewMode) {
        toggleFormReview();
      }
    } else {
      alert.error('Please fix all the errors in this form to proceed.');
    }
  }

  function getMoveAheadButton() {
    if (reviewMode) {
      return (
        <PrimaryButton
          key="form-submit-button"
          type="submit"
          size="tiny"
          css={primaryButtonStyle}
          data-testid={'submit-button'}
        >
          Submit
        </PrimaryButton>
      );
    }

    const buttonText = showReviewButton ? 'Review' : 'Next';

    if (showReviewButton && disableReviewMode) {
      return null;
    }

    return (
      <PrimaryButton
        type="button"
        onClick={handleNext}
        size="tiny"
        css={primaryButtonStyle}
        data-testid={showReviewButton ? 'review-button' : 'next-button'}
      >
        {buttonText}
      </PrimaryButton>
    );
  }

  const CompanyBadge = () =>
    logoSrc ? (
      <CompanyLogo logoSrc={logoSrc} />
    ) : (
      <Heading level={1} textAlign="center">
        {officeName}
      </Heading>
    );

  function PatientNameBadge() {
    if (!firstName) return <CompanyBadge />;

    /* show patient name if link is generate for a dependent */

    return (
      <div css={headerDependentContainerStyle(allSectionsRef.current.length === 1)}>
        <CompanyBadge />
        <div className="containter">
          <Heading level={2} className="dependent-name">
            {!isFormForDependent && <FamilyIcon color="light" size={16} />}
            {` ${firstName}`} {!!lastName ? lastName : ''}
          </Heading>
          {isFormForDependent && (
            <Chip variant="primary" color="blue" className="badge">
              <FamilyIcon color="primary" size={18} /> Dependent
            </Chip>
          )}
        </div>
      </div>
    );
  }

  const getFormContent = () => {
    if (reviewMode) {
      return (
        <>
          <CompanyBadge />
          <FormReview forms={modifiedForms} />
        </>
      );
    }
    return (
      <>
        {/* Only show country field when 
              1. it's a solicited form
              2. it's not a preview mode
              3. it's the first section of the form
          */}
        {isCountryDropdownEnabled &&
          !patronInfoReady &&
          allSectionsRef.current.indexOf(currentSection) === 0 &&
          !previewMode && <CountryField />}
        <PatientNameBadge />
        {/* Incase single page form we've to hide the progress bar */}
        {allSectionsRef.current.length !== 1 && (
          <FormProgressBar steps={allSectionsRef.current} currentStep={currentSection} />
        )}
        <Heading level={2} textAlign="left" className="form-title">
          {currentForm.form.name}
        </Heading>
        {sectionTitle && (
          <Heading level={3} className="section-title">
            {sectionTitle}
          </Heading>
        )}
        <FormElements
          form={currentForm}
          fieldsToShow={fieldsInThisSection}
          conditionMapper={conditionMapper}
          onChangeInput={inputFocusAndChangeHandler}
          onFocusInput={inputFocusAndChangeHandler}
        />
      </>
    );
  };

  return (
    <form
      css={formStyle}
      noValidate={true}
      onSubmit={handleSubmit}
      onChange={() => setHasUnsavedChanges(true)}
    >
      <div css={scrollContainerStyle} ref={formContentRef}>
        <div css={formContentContainerStyle}>
          {getFormContent()}

          <IdleKioskRedirectionModal
            {...idleTimerProps}
            onCountDownElapse={redirectToKiosk}
          />
        </div>
        {!hideFooterLinks && <FooterLinks companyId={companyToken} />}

        <ScrollCue {...scrollCueProps} />
      </div>

      <div css={getFormFooterStyle({ hasScroll })}>
        <div className="buttons">
          {(showPrevButton || reviewMode) && (
            <SecondaryButton onClick={handleBack} size="tiny">
              Previous
            </SecondaryButton>
          )}

          {getMoveAheadButton()}
        </div>
      </div>
    </form>
  );
};

export default CustomForm;
