import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import axios, { AxiosError } from 'axios';
import { isArray } from 'lodash';
import * as Sentry from '@sentry/nextjs';

import {
  FormTemplateDataResponse,
  FormExperiencePageProps,
  ConditionMapper,
  ConditionMapperForField,
  FormTemplateResponse,
  FormTemplateFetchError,
  FormTemplateData,
  ParsedFormTemplateField,
  ParsedFormTemplate,
  FormFieldOptions,
  FormType,
  FormTemplateType,
  FormTemplateFieldData,
} from '@forms-exp/types';
import { environmentVariables, getEnvProps } from '@forms-exp/env';
import { getCountryDropdownFlag } from '../country-dropdown';

type ValidatedFormData =
  | { success: true; data: ParsedFormTemplate }
  | { success: false; data: null };

interface CheckInvalidFormDataPayload {
  apiResponse: FormTemplateResponse;
  formType?: FormType | FormTemplateType | null;
  companyId?: string;
}

/**
 * Function to remove invalid form data (e.g., Forms that were deleted
 * without removing it from a packet) from the forms array.
 */
function checkForInvalidFormData({
  apiResponse,
  formType,
  companyId,
}: CheckInvalidFormDataPayload): ValidatedFormData {
  if (apiResponse?.isSubmitted) {
    return {
      success: true,
      data: {
        companyId: companyId || '',
        data: [],
        expiryDate: '',
        personDetails: undefined,
        isSubmitted: true,
      },
    };
  }

  if (!apiResponse || !apiResponse.data) {
    return { success: false, data: null };
  }

  let forms = JSON.parse(apiResponse.data as string);

  const parsedResponse: ParsedFormTemplate = {
    companyId: apiResponse.companyId || companyId || '',
    isSubmitted: apiResponse.isSubmitted,
    logoUrl: apiResponse.logoUrl,
    data: [],
    personDetails: apiResponse?.personDetails || undefined,
    medicalHistory: apiResponse?.medicalHistory || undefined,
    primaryInsurance: apiResponse?.primaryInsurance || undefined,
    secondaryInsurance: apiResponse?.secondaryInsurance || undefined,
    prePopulateElements: apiResponse?.prePopulateElements || undefined,
    expiryDate: apiResponse?.expiryDate,
  };

  if (formType === 'Template') {
    // Response for template comes as an object.
    // Response of form, packet, solicited form comes as and array of objects.
    // Adding the array layer to keep the logic uniform.
    forms = [forms];
  }

  // "fields" is found to be empty for invalid forms!
  parsedResponse.data = (forms as FormTemplateDataResponse[])
    .filter((form) => form.fields !== null)
    .map((form) => {
      const fields = form.fields;
      const parsedField: ParsedFormTemplateField = {};

      /**
       * THIS SHOULD NOT BE CHANGED!
       * This code is in place to create backward compatability.
       */
      for (const fieldId in fields) {
        parsedField[fieldId] = { ...fields[fieldId], options: undefined };
        delete (parsedField[fieldId] as FormTemplateFieldData<FormFieldOptions[]>)
          .options;

        if (fields[fieldId].options) {
          /**
           * The new Go API stringifies the options array, so it should
           * be parsed before used in the form.
           */
          if (typeof fields[fieldId].options === 'string') {
            try {
              parsedField[fieldId].options = JSON.parse(
                fields[fieldId].options! as string
              );

              // If the parsed string is still a string, try parsing it again.
              if (typeof parsedField[fieldId].options === 'string') {
                parsedField[fieldId].options = JSON.parse(
                  JSON.parse(fields[fieldId].options! as string)
                );
              }
            } catch (e) {
              parsedField[fieldId].options = [];
            }
          } else {
            parsedField[fieldId].options = fields[fieldId].options as FormFieldOptions[];
          }
        }

        const { meta, options } = fields[fieldId];

        if (
          meta.dataGroup === 'medicalHistory' &&
          isArray(options) &&
          options.length > 0
        ) {
          // Filter out disabled medical history options.
          parsedField[fieldId].options = options.filter((option) => !option.disabled);
        }
      }

      const sectionsObject = form.sections;
      const sectionsArray = form.form.sections;

      for (const sectionId in sectionsObject) {
        // Remove blank sections.
        // Blank sections won't have the "fields" property.
        if (!sectionsObject[sectionId].fields) {
          const index = sectionsArray.indexOf(sectionId);

          if (index !== -1) {
            sectionsArray.splice(index, 1);
          }

          delete sectionsObject[sectionId];
        }
      }

      const parsedForm: FormTemplateData = {
        ...form,
        fields: parsedField,
      };

      return parsedForm;
    });

  return { success: true, data: parsedResponse };
}

interface BaseFetchFormParams {
  secretCode?: string;
}

interface FetchFormParams extends BaseFetchFormParams {
  token: string;
  formType?: FormType | null;
  companyId?: string;
}

interface FetchTemplateParams extends BaseFetchFormParams {
  token: string;
  formType: FormTemplateType;
  companyId: string;
}

export async function fetchFormData({
  token,
  formType,
  companyId,
  secretCode,
}: FetchFormParams | FetchTemplateParams): Promise<
  ParsedFormTemplate | FormTemplateFetchError
> {
  const API_BASE = `${environmentVariables.baseApiUrl}/forms-digital/v1`;
  let params: Record<string, string> = { includeOptions: 'true' };
  let apiEndpoint = API_BASE;

  if (secretCode) {
    params = {
      ...params,
      secretCode: secretCode,
    };
  }

  switch (formType) {
    case 'Form':
      params = {
        ...params,
        form_id: token,
      };
      break;

    case 'Packet':
      params = {
        ...params,
        packet_id: token,
      };
      break;

    case 'Template':
      apiEndpoint = `${API_BASE}/template-body`;

      if (!companyId) {
        throw new Error('Company ID not provided for the template!');
      }

      params = {
        ...params,
        companyId,
        templateId: token,
      };
      break;

    default:
      // Assume it is a solicited form by default.
      params = {
        ...params,
        qwe: token,
      };
  }

  if (companyId && formType !== 'Template') {
    params = {
      ...params,
      company_id: companyId,
    };
  }

  try {
    const { data } = await axios.get<FormTemplateResponse>(apiEndpoint, {
      params,
    });
    const validationResult = checkForInvalidFormData({
      apiResponse: data,
      formType,
      companyId,
    });

    return validationResult.success
      ? { ...validationResult.data, ...(secretCode && { otpValidated: true }) }
      : {
          message: 'Invalid form data',
          error: true,
          ...(secretCode && { otpValidated: false }),
        };
  } catch (err) {
    const e = err as AxiosError;
    console.log('Error fetching the form:', e);

    const isServerError = (e?.response?.status ?? 400) >= 500;

    return {
      message: 'Form not found!',
      error: true,
      ...(secretCode && { otpValidated: false, serverError: isServerError }),
    };
  }
}

export async function fetchLocationName(locationId: string) {
  try {
    const apiEndpoint = `${environmentVariables.baseApiUrl}/portal/locations/${locationId}`;
    const { data } = await axios.get(apiEndpoint);

    if (data.data?.length > 0) {
      return data.data[0].name;
    }

    return '';
  } catch (e) {
    console.log('Error fetching the location name', e);
    return '';
  }
}

export interface FetchFormDataParams {
  company_id?: string;
  companyId?: string;
  formId?: string;
  packetId?: string;
  qwe?: string;
  templateId?: string;
  hideFooterLinks?: string;
  disableReviewMode?: string;
  secret_code?: string;
}

export async function prepareFormExperienceProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<FormExperiencePageProps>> {
  /**
   * qwe -> Solicited Submission Token
   */
  const {
    query: {
      packetId,
      qwe,
      templateId,
      hideFooterLinks,
      disableReviewMode,
      formId,
      companyId,
      company_id,
    },
  } = context;

  const locationId = (companyId ?? company_id) as string | undefined;

  const isForm = formId !== undefined;
  const isPacket = packetId !== undefined;
  const isTemplate = templateId !== undefined;
  const token = (formId || packetId || qwe || templateId) as string;
  const shouldHideFooterLinks = hideFooterLinks === 'true';
  const shouldDisableReviewMode = disableReviewMode === 'true';

  // custom keys don't work so we use token as username and companyId as id
  Sentry.setUser({ username: token, ...(locationId && { id: locationId }) });

  let props: FormExperiencePageProps = {
    forms: [],
    formToken: '',
    companyToken: '',
    formType: null,
    isGenericForm: false,
    logoSrc: '',
    notFound: false,
    fatalError: false,
    isSubmitted: false,
    conditionMapper: {},
    officeName: '',
    person: null,
    hideFooterLinks: shouldHideFooterLinks,
    disableReviewMode: shouldDisableReviewMode,
    expiryDate: '',
    medicalHistory: null,
    primaryInsurance: null,
    secondaryInsurance: null,
    prePopulateElements: null,
    isCountryDropdownEnabled: false,
    ...getEnvProps(),
  };

  if (!token) {
    return { props };
  }
  if (!!locationId) {
    const data = await getCountryDropdownFlag(locationId);
    props = { ...props, isCountryDropdownEnabled: data };
  }

  let data;
  let formType: FormType | FormTemplateType | null = null;

  if (isTemplate) {
    if (!locationId) {
      return { props };
    }

    formType = 'Template';
    data = await fetchFormData({ token, formType, companyId: locationId });
  } else {
    formType = (isForm && 'Form') || (isPacket && 'Packet') || null;
    data = await fetchFormData({ token, formType, companyId: locationId });
  }
  if ((data as ParsedFormTemplate).isSubmitted) {
    return {
      props: {
        ...props,
        isSubmitted: true,
      },
    };
  }

  if (!(data as ParsedFormTemplate).data) {
    return {
      props: {
        ...props,
        notFound: true,
      },
    };
  }

  if ((data as FormTemplateFetchError).error) {
    return {
      props: {
        ...props,
        fatalError: true,
      },
    };
  }

  const conditionMapper: ConditionMapper = {};

  for (const form of (data as ParsedFormTemplate).data as FormTemplateData[]) {
    if (form.conditions) {
      const mappedConditions: ConditionMapperForField = {};

      form.conditions.forEach((condition, index) => {
        mappedConditions[condition.id] = index;
      });

      conditionMapper[form.form.id] = mappedConditions;
    }
  }

  const officeName = await fetchLocationName((data as ParsedFormTemplate).companyId);

  props = {
    ...props,
    forms: (data as ParsedFormTemplate).data,
    conditionMapper,
    formToken: token,
    companyToken: (data as ParsedFormTemplate).companyId,
    officeName,
    logoSrc: (data as ParsedFormTemplate).logoUrl || '',
    formType,
    expiryDate: (data as ParsedFormTemplate).expiryDate || '',
    person: (data as ParsedFormTemplate).personDetails || null,
    medicalHistory: (data as ParsedFormTemplate).medicalHistory || null,
    primaryInsurance: (data as ParsedFormTemplate).primaryInsurance || null,
    secondaryInsurance: (data as ParsedFormTemplate).secondaryInsurance || null,
    prePopulateElements: (data as ParsedFormTemplate).prePopulateElements || null,
  };

  return { props };
}
