import { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { ContentLoader } from '@weave/design-system';

import useStore from '@forms-exp/store';
import { useLeavePageConfirm } from '@forms-exp/hooks';

import PreAdmissionRegistrationForm from './pre-admission-registration-form/pre-admission-registration-form.component';
import CustomForm from './custom-form/custom-form.component';
import SubmissionConfirmation, {
  SubmissionTypes,
} from './submission-confirmation/submission-confirmation.component';
import { usePrePopulate } from './use-pre-populate';

import { FormExperienceProps, SubmissionStatusState } from './form-experience.type';

import { containerStyle, mainContentContainerStyle } from './form-experience.styles';

export const FormExperience: FC<FormExperienceProps> = (props) => {
  const {
    forms = [],
    previewMode = false,
    hideFooterLinks = false,
    disableReviewMode = false,
    logoSrc,
    companyToken,
    formType,
    officeName,
    conditionMapper,
    person,
    medicalHistory,
    prePopulateElements,
    primaryInsurance,
    secondaryInsurance,
    isCountryDropdownEnabled,
  } = props;
  const isSolicitedForm = formType === null; // 'formType' will be null for solicited forms.

  const allSectionsRef = useRef<string[]>([]);
  const [isPageReady, setIsPageReady] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const { initialized, patronInfoReady, initFields, clearFormExp, clearFormPacket } =
    useStore();

  const {
    prefillPrimaryData,
    prefillPrimaryInsurance,
    prefillSecondaryInsurance,
    prefillMedicalHistory,
    prefillElements,
  } = usePrePopulate();

  useLeavePageConfirm();

  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatusState>({
    submitted: false,
    id: '',
    data: '',
  });

  const router = useRouter();
  // ni -> "Not Integrated" location
  const { kiosk, ni } = router.query as { kiosk?: string; ni?: string };

  useEffect(() => {
    if (!isPageReady) {
      // Redirect to kiosk home if the page is refershed while in kiosk mode.
      if (kiosk && !patronInfoReady && ni === undefined) {
        router.replace(`/kiosk/${kiosk}`);
      } else {
        setIsPageReady(true);
      }
    }
  }, [isPageReady, kiosk, patronInfoReady, router, ni]);

  useEffect(() => {
    return () => {
      // Clean up the form experience state when the component is destroyed.
      clearFormExp();
      clearFormPacket();
    };
  }, [clearFormExp, clearFormPacket]);

  useEffect(() => {
    // Setup the state for all the fields in the forms.
    if (forms.length > 0 && !initialized) {
      let fieldsToInit = {};
      const initialFormSections = forms[0].form.sections;
      const initialSection = initialFormSections.length > 0 ? initialFormSections[0] : '';
      let allSections: string[] = [];

      for (const form of forms) {
        fieldsToInit = { ...fieldsToInit, ...form.fields };
        allSections = allSections.concat(form.form.sections);
      }

      allSectionsRef.current = allSections;
      initFields({
        fieldsToAdd: fieldsToInit,
        initialSection,
        conditionMapper,
        previewMode,
      });

      prefillPrimaryData(person || {});

      if (medicalHistory) {
        prefillMedicalHistory(medicalHistory);
      }

      if (primaryInsurance) {
        prefillPrimaryInsurance(primaryInsurance);
      }

      if (secondaryInsurance) {
        prefillSecondaryInsurance(secondaryInsurance);
      }

      if (prePopulateElements) {
        prefillElements(prePopulateElements);
      }
    }
  }, [forms, initialized, initFields, previewMode, conditionMapper]);

  if (!isPageReady) {
    return <ContentLoader show />;
  }

  let mainContent;

  if (submissionStatus.submitted) {
    let mode: SubmissionTypes;

    if (previewMode) {
      mode = 'preview';
    } else if (kiosk) {
      mode = 'kiosk';
    }

    mainContent = (
      <SubmissionConfirmation
        mode={mode}
        submissionId={submissionStatus.id}
        companyId={companyToken}
      />
    );
  } else if (!patronInfoReady && !isSolicitedForm && !previewMode) {
    // Show this only for generic link forms.
    mainContent = (
      <PreAdmissionRegistrationForm
        logoSrc={logoSrc}
        companyId={companyToken}
        officeName={officeName}
        isCountryDropdownEnabled={isCountryDropdownEnabled}
      />
    );
  } else if (initialized) {
    mainContent = (
      <>
        <Head>
          <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
        </Head>
        <CustomForm
          {...props}
          allSectionsRef={allSectionsRef}
          submissionInProgress={submissionInProgress}
          setSubmissionStatus={setSubmissionStatus}
          setSubmissionInProgress={setSubmissionInProgress}
          hideFooterLinks={hideFooterLinks}
          disableReviewMode={disableReviewMode}
          isSolicitedForm={isSolicitedForm}
        />
      </>
    );
  }

  return (
    <div css={containerStyle}>
      <main css={mainContentContainerStyle}>{mainContent}</main>
      <ContentLoader
        backgroundOpacity={1}
        show={submissionInProgress}
        message="Submitting your form..."
      />
    </div>
  );
};
