import { useState, useEffect } from 'react';

import { fetchLocationName } from '@forms-exp/helpers';

export const useCompanyName = (companyId: string, enabled: boolean = true) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (enabled) {
      fetchLocationName(companyId).then((name) => {
        setName(name);
      });
    }
  }, [companyId, enabled]);

  return name;
};
